const environment = {
  name: "dev",
  production: false,
  api: {
    deposits: "https://spring-dev.allica.co.uk/v1/cjcustomer-deposits",
    payee: "https://spring-dev.allica.co.uk/v1/confirmation-of-payee",
    products: "https://spring-dev.allica.co.uk/v1/cjcustomer-products",
    companyHouse: "https://spring-dev.allica.co.uk/v1/companieshouse",
    bcaProducts: "https://spring-dev.allica.co.uk/v1/bca/products",
    savingsAccounts: "https://spring-dev.allica.co.uk/v1/savings/accounts",
    validation: "https://spring-dev.allica.co.uk/v1/validation",
  },
  ui: {
    onlineBanking: "https://web-dev.allica.co.uk/v2-online-banking",
    allicaPortal: "https://www.allica.bank",
  },
  appInsights: {
    enabled: true,
    role: "cj-customer-deposits-swa-dev",
    config: {
      instrumentationKey: "805dfa31-501b-4bb8-bede-e0b41d2d20aa",
      enableRequestHeaderTracking: true,
      enableResponseHeaderTracking: true,
    },
  },
  googleTagManager: {
    enabled: true,
    config: {
      gtmId: "GTM-WZ4WCM9Z",
    },
  },
  googleReCAPTCHA: {
    enabled: true,
    config: {
      siteKey: "6LedjvwpAAAAAPA3pbaKx4p0fik64J1xoZEfAM2w",
      loadWithTagManager: true,
    },
  },
  ping: { resendTimer: 5 },
};

export default environment;
